import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash'

import { assincronosApi } from '../../../../../Services/api';
import Loading from '../../../../../Services/Loading';
import TextoEstilosDeComunicacao from '../../../../Components/TextoEstilosDeComunicacao';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import EstiloDeComunicacaoGraph2 from 'views/Pages/Assets/ModalResultado/Resultados/components/EstiloDeComunicacaoGraph';
import AnswerersEstiloDeComunicacaoGraph from 'views/Pages/Assets/ModalResultado/Resultados/components/AnswerersEstiloDeComunicacaoGraph';
import apiCalls from 'config/apiCalls';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
import Folder from 'views/Pages/Assets/Folder';
import EstiloComunicacaoTextResultModal from 'views/Components/EstiloComunicacaoTextResultModal';

import './css/EstiloDeComunicacao.css'

/**
 * Renderiza o resultado de uma ancora de carreira de um usuário
 *
 * @export
 * @class ResultadoEstilosDeComunicacao
 * @extends {Component}
 */
export default class ResultadoEstilosDeComunicacao extends Component {

  state = {
    resultado: {
      respostas: [],
      respondentes: [],
    },
    loading: false,
    textosAutoAvaliado: [],
    textosRespondentes: [],
    confortableTexts: [],
    stressTexts: [],
    dimensoesMaisPontuadasAutoAvaliada: [[]],
    dimensoesMaisPontuadasRespondente: [[]],
    whatsBeingMeasuredText: '',
    answererFinished: false,
    answerers: null,
    userResults: null,
    textResultModal: {
      isOpen: false,
      textType: '',
      answerersGroup: '',
      estiloType: '',
      results: []
    },
    bestResultFavoravel: {
      results: [],
      points: 0,
    },
    bestResultEstresse: {
      results: [],
      points: 0,
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });

      const resGroupAnswerersResult = await apiCalls.assincronos.getGroupAnswerersResults({ userId: this.props.idParticipante, assincId: this.props.idAssincrono, projectId: this.props.idProjeto, instrumentId: this.props.idInstrumento })

      const { grafico, answerers } = resGroupAnswerersResult.data;

      if (grafico) {
        let bestResultFavoravel = {
          results: [],
          points: 0,
        }

        let bestResultEstresse = {
          results: [],
          points: 0,
        }

        const promises = Object.keys(grafico).map(async key => {
          const attrResultsFavoravel = []
          const attrResultsEstresse = []

          if (grafico[key][`s`]) {
            attrResultsFavoravel.push({ ansGroup: key, name: 'Afetivo', points: grafico[key][`s`] })
          }

          if (grafico[key][`pn`]) {
            attrResultsFavoravel.push({ ansGroup: key, name: 'Racional', points: grafico[key][`pn`] })
          }

          if (grafico[key][`pr`]) {
            attrResultsFavoravel.push({ ansGroup: key, name: 'Pragmático', points: grafico[key][`pr`] })
          }

          if (grafico[key][`i`]) {
            attrResultsFavoravel.push({ ansGroup: key, name: 'Reflexivo', points: grafico[key][`i`] })
          }

          if (grafico[key][`sS`]) {
            attrResultsEstresse.push({ ansGroup: key, name: 'Afetivo', points: grafico[key][`sS`] })
          }

          if (grafico[key][`pnS`]) {
            attrResultsEstresse.push({ ansGroup: key, name: 'Racional', points: grafico[key][`pnS`] })
          }

          if (grafico[key][`prS`]) {
            attrResultsEstresse.push({ ansGroup: key, name: 'Pragmático', points: grafico[key][`prS`] })
          }

          if (grafico[key][`iS`]) {
            attrResultsEstresse.push({ ansGroup: key, name: 'Reflexivo', points: grafico[key][`iS`] })
          }

          const orderedFavoravelResults = _.orderBy(attrResultsFavoravel, ['points'], ['desc'])
          const orderedEstresseResults = _.orderBy(attrResultsEstresse, ['points'], ['desc'])

          const newBestResultFavoravel = {
            results: [{name: [orderedFavoravelResults[0].name], ansGroup: orderedFavoravelResults[0].ansGroup}],
            points: orderedFavoravelResults[0].points,
          }

          orderedFavoravelResults.map((r, i) => {
            if (i > 0 && r.points > newBestResultFavoravel.points) {
              newBestResultFavoravel.results.push({ name: [r.name], ansGroup: r.ansGroup })
              newBestResultFavoravel.points = r.points
            } else if (i > 0 && r.points === newBestResultFavoravel.points) {
              const finded = newBestResultFavoravel.results.find(res => res.ansGroup === r.ansGroup)

              if (finded) {
                finded.name.push(r.name)
              }
            }
          })

          const newBestResultEstresse = {
            results: [{name: [orderedEstresseResults[0].name], ansGroup: orderedEstresseResults[0].ansGroup}],
            points: orderedEstresseResults[0].points,
          }

          orderedEstresseResults.map((r, i) => {
            if (i > 0 && r.points > newBestResultEstresse.points) {
              newBestResultEstresse.results.push({ name: [r.name], ansGroup: r.ansGroup })
              newBestResultEstresse.points = r.points
            } else if (i > 0 && r.points === newBestResultEstresse.points) {
              const finded = newBestResultEstresse.results.find(res => res.ansGroup === r.ansGroup)

              if (finded) {
                finded.name.push(r.name)
              }
            }
          })

          bestResultFavoravel.results = bestResultFavoravel.results.concat(newBestResultFavoravel.results)
          bestResultEstresse.results = bestResultEstresse.results.concat(newBestResultEstresse.results)

          const { texts } = await this.getTextosAnswerers(grafico[key].relationId)


          grafico[key].texts = texts

          if (!this.state.whatsBeingMeasuredText) {
            this.setState({ whatsBeingMeasuredText: texts.whatsBeingMeasuredText })
          }
        })

        await Promise.all(promises)

        this.setState({ bestResultFavoravel, bestResultEstresse })
      }


      const resInfoAnswerers = await apiCalls.resultados.assincronos.getInfoInstrumentoAnswerer(this.props.idProjeto, this.props.idParticipante, this.props.idAssincrono, this.props.idInstrumento)


      const resInfoInst = await apiCalls.resultados.assincronos
      .getInfoInstrumento(
        this.props.idProjeto,
        this.props.idParticipante,
        this.props.idAssincrono,
        this.props.idInstrumento
      )


      const answers = resInfoInst.data

      if (answers && answers.length) {
        await this.getTextos()
      }

      this.setState({
        answerers,
        userResults: grafico ? grafico : null,
        answererFinished: resInfoAnswerers.data.answererFinished,
        resultado: {
          ...this.state.resultado,
          respostas: answers
        },
        dimensoesMaisPontuadasAutoAvaliada: this.getDimensoesMaisPontuadas(
          answers[0],
        ),
        dimensoesMaisPontuadasRespondente: this.getDimensoesMaisPontuadas(
          this.getRespondenteResposta(this.props.resultado),
        ),
        textosAutoAvaliado: [],
        textosRespondentes: [],
        loading: false,
      })
    } catch (err) {
      console.error(err)
      this.setState({ loading: false })
    }
  }

  async getTextoFromAncora(ancora) {
    const res = await assincronosApi.get(
      `/resultado/estilo-de-comunicacao/texto-consultor/${ancora.dimensaoName}`,
    );
    return res.data;
  }

  getResultsTexts = async () => {
    try {
      const res = await apiCalls.assincronos.getEstilosComunicacaoTexts(
        this.props.idParticipante,
        this.props.idAssincrono,
        this.props.idInstrumento
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return {
        participant_modal_texts: {
          confortable_situation_texts: [],
          stress_situation_texts: [],
          whats_being_measured_text: ''
        }
      }
    }
  }

  async getTextos() {
    const { participant_modal_texts: texts, whats_being_measured_text } = await this.getResultsTexts();
    const { confortable_situation_texts, stress_situation_texts  } = texts;
    this.setState({
      confortableTexts: confortable_situation_texts,
      stressTexts: stress_situation_texts,
      whatsBeingMeasuredText: whats_being_measured_text
    });
  }

  getResultsTextsAnswerers = async (answererId) => {
    try {
      const res = await apiCalls.assincronos.getEstilosComunicacaoTextsAnswerers(
        this.props.idParticipante,
        this.props.idAssincrono,
        this.props.idInstrumento,
        answererId
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return {
        participant_modal_texts: {
          confortable_situation_texts: [],
          stress_situation_texts: [],
          whats_being_measured_text: ''
        }
      }
    }
  }

  async getTextosAnswerers(answererId) {
    const { participant_modal_texts: texts, whats_being_measured_text } = await this.getResultsTextsAnswerers(answererId);

    const { confortable_situation_texts, stress_situation_texts  } = texts;

    return { texts: {
      confortableTexts: confortable_situation_texts,
      stressTexts: stress_situation_texts,
      whatsBeingMeasuredText: whats_being_measured_text
    } }
  }

  /**
   * Retorna a média das respostas do respondente
   *
   * @param {InstrumentoInterno} instrumento
   * @returns {Map<String, number>}
   * @memberof ResultadoEstilosDeComunicacao
   */
  getRespondenteResposta(instrumento) {
    let respostas = {
      pr: 0,
      s: 0,
      pn: 0,
      i: 0,
      prS: 0,
      sS: 0,
      pnS: 0,
      iS: 0,
    };
    instrumento.respondentes.forEach(respondente => {
      if (respondente.resposta) {
        Object.keys(respondente.resposta).forEach(key => {
          respostas[key] += respondente.resposta[key];
        });
      }
    });
    return respostas;
  }

  getDimensoesMaisPontuadas(answer) {
    try {
    let dimensoesAncoras = [];
    let dimensoesAncorasStress = [];
    for (let key in answer) {
      if (!key.includes('escolhas')) {
        if (key.includes('S')) {
          let dimensaoName = key.split('S')[0];
          dimensoesAncorasStress.push({
            dimensaoName,
            pontos: answer[key],
          });
        } else {
          let dimensaoName = key;
          dimensoesAncoras.push({
            dimensaoName,
            pontos: answer[key],
          });
        }
      }
    }
    const dimensaoMaisPontuada = dimensoesAncoras.reduce((acc, at) =>
      at.pontos > acc.pontos ? at : acc,
    );
    const dimensaoMaisPontuadaStress = dimensoesAncorasStress.reduce(
      (acc, at) => (at.pontos > acc.pontos ? at : acc),
    );
    const dimensoesMaisPontuadas = dimensoesAncoras.filter(
      d => d.pontos === dimensaoMaisPontuada.pontos,
    );
    const dimensoesMaisPontuadasStress = dimensoesAncorasStress.filter(
      d => d.pontos === dimensaoMaisPontuadaStress.pontos,
    );
    return [dimensoesMaisPontuadas, dimensoesMaisPontuadasStress];
    } catch(err) {
      console.error(err);
      return [[], []];
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  toggleTextResultModal = (answerersGroup, textType, estiloType='') => {
    if (!this.state.textResultModal.isOpen) {

      const results = {
        ...this.state.userResults[answerersGroup],
      }

      this.setState({ textResultModal: {
        isOpen: !this.state.textResultModal.isOpen,
        answerersGroup,
        textType,
        estiloType,
        results
      } })
    } else {
      this.setState({ textResultModal: {
        isOpen: !this.state.textResultModal.isOpen,
        answerersGroup: '',
        textType: '',
        estiloType: '',
        results: null
      } })
    }
  }

  render() {
    const {
      loading,
      textosAutoAvaliado,
      dimensoesMaisPontuadasAutoAvaliada,
      dimensoesMaisPontuadasRespondente,
      resultado
    } = this.state;
    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <EstiloComunicacaoTextResultModal
          isOpen={this.state.textResultModal.isOpen}
          toggle={this.toggleTextResultModal}
          textType={this.state.textResultModal.textType}
          answerersGroup={this.state.textResultModal.answerersGroup}
          estiloType = {this.state.textResultModal.estiloType}
          results = {this.state.textResultModal.results}
        />

      { resultado.respostas.length ?<>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>Estilo de comunicação - Autoavaliação</span>
        </div> <br />
        <Row style={{ marginBottom: 60 }}>
          <Col sm='6'>
            <EstiloDeComunicacaoGraph2
              participantId={this.props.idParticipante}
              assincronoId={this.props.idAssincrono}
              instrumentId={this.props.idInstrumento}
              projectId={this.props.idProjeto}
              graphType="situacao_confortavel"
              id={`estilo-comunicacao-confortavel-auto-${this.props.idAssincrono}-${this.props.idParticipante}`}
            />
          </Col>
          <Col sm='6'>
            <EstiloDeComunicacaoGraph2
              participantId={this.props.idParticipante}
              assincronoId={this.props.idAssincrono}
              instrumentId={this.props.idInstrumento}
              projectId={this.props.idProjeto}
              graphType="situacao_stress"
              id={`estilo-comunicacao-stress-auto-${this.props.idAssincrono}-${this.props.idParticipante}`}
            />
          </Col>
        </Row>
        </> : null}

        {
          this.state.answererFinished && this.state.userResults ? <>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <span style={{ fontSize: 14, fontWeight: 'bold' }}>Estilo de comunicação - Stakeholders</span>
          </div> <br />
          <Row className='answerers-estilo-comunicacao-div-wrapper' style={{ marginBottom: 20 }}>
            <Col className='answerers-estilo-comunicacao-col-wrapper' sm={6}>
              <AnswerersEstiloDeComunicacaoGraph
                title={'Condição Favorável'}
                results={this.state.userResults}
                setAnsGroupColor={(group, color) => {
                  const finded = this.state.bestResultFavoravel.results.find(r => r.ansGroup === group)

                  if (finded) {
                    finded.color = color;

                    const newResults = this.state.bestResultFavoravel.results.filter(r => r.ansGroup !== group)

                    this.setState({
                      bestResultFavoravel: {
                        ...this.state.bestResultFavoravel,
                        results: [...newResults, finded]
                      }
                    })
                  }
                }}
              />
            </Col>
            <Col className='answerers-estilo-comunicacao-col-wrapper' sm={6}>
              <AnswerersEstiloDeComunicacaoGraph
                title={'Condição Sob Estresse'}
                results={this.state.userResults}
                setAnsGroupColor={(group, color) => {
                  const finded = this.state.bestResultEstresse.results.find(r => r.ansGroup === group)

                  if (finded) {
                    finded.color = color;

                    const newResults = this.state.bestResultEstresse.results.filter(r => r.ansGroup !== group)

                    this.setState({
                      bestResultEstresse: {
                        ...this.state.bestResultEstresse,
                        results: [...newResults, finded]
                      }
                    })
                  }
                }}
              />
            </Col>

          </Row></> : null
        }

        { (!this.state.answererFinished || !this.state.userResults) && !resultado.respostas.length ? <><NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound><br/><br/></> : <><Row>
          <Col>
            <p>
              <WhatsBeingMeasured text={this.state.whatsBeingMeasuredText} />
              <br />
            </p>
          </Col>
        </Row></> }

        <Folder
          question={true}
          title={'O resultado da sua autoavaliação não considera a avaliação dos respondentes.'}
          nome={`Autoavaliação`}
          key={'estilos-comunicacao-autoavaliacao-folder'}
          show={true}
        >
          { resultado.respostas.length ? <>
          {/* <h3>
            {(dimensoesMaisPontuadasAutoAvaliada[0][0] &&
              dimensoesMaisPontuadasAutoAvaliada[0][0].pontos > 0) ||
            (dimensoesMaisPontuadasRespondente[0][0] &&
              dimensoesMaisPontuadasRespondente[0][0].pontos > 0)
              ? this.state.resultado.nome || 'Grupo legal'
              : null}
          </h3> */}

          <Row>
            <Col>
              <p>
                <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>Resultado</h3>
              </p>
            </Col>
          </Row>

          <br />

          <Row>
            <Col sm='6'>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '600'
              }}>Condição Favorável</h3>
            </Col>
            <Col sm='6'>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '600'
              }}>Condição Sob Estresse</h3>
            </Col>
          </Row>

          <br />

          <Row>
            {dimensoesMaisPontuadasAutoAvaliada[0][0] &&
            dimensoesMaisPontuadasAutoAvaliada[0][0].pontos > 0 ? (
              <>
              <Col sm='6'>
              {this.state.confortableTexts.map(textObj => (
                  <TextoEstilosDeComunicacao
                  textObj={textObj} />
              ))}
              </Col>
              <Col sm='6'>
              {this.state.stressTexts.map(textObj => (
                  <TextoEstilosDeComunicacao
                  textObj={textObj} />
              ))}
              </Col></>
            )  : (
              <p>Sem dados</p>
            )}
          </Row></>
          : <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>}
        </Folder>
        <Folder
          nome={`Stakeholders`}
          key={'estilos-comunicacao-respondentes-folder'}
          show={false}
        >
          { this.state.answererFinished && this.state.userResults ? <>
          <Row style={{ marginTop: 20 }}>
            <h3 style={{
              fontSize: '18px',
              fontWeight: '600'
            }}>Resultado</h3>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col sm={6}>
              <h3 style={{
                fontSize: '18px',
                fontWeight: '600'
              }}>Condição Favorável</h3>
                { this.state.bestResultFavoravel.results.map(r => (
                  <Row>
                    <Col><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>{r.color ? <div style={{ marginRight: 10, height: 10, borderRadius: '20%', width: 20, background: r.color }}></div> : null}<span>{r.ansGroup} - {
                      r.name.map((name, index) => (
                        <>
                          { index > 0 ? ' / ' : null }
                          <a href='#' onClick={(e) => {
                            e.preventDefault()
                            this.toggleTextResultModal(r.ansGroup, name, 'favorável')
                          }}>{name}</a>
                        </>
                      ))
                    }

                    </span></div></Col>
                  </Row>
                )) }
            </Col>
            <Col sm={6}>
              <h3 style={{
                  fontSize: '18px',
                  fontWeight: '600'
                }}>Condição Sob Estresse</h3>
                { this.state.bestResultEstresse.results.map(r => (
                  <Row>
                    <Col><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>{r.color ? <div style={{ marginRight: 10, height: 10, borderRadius: '20%', width: 20, background: r.color }}></div> : null} <span>{r.ansGroup} - {
                      r.name.map((name, index) => (
                        <>
                          { index > 0 ? ' / ' : null }
                          <a href='#' onClick={(e) => {
                            e.preventDefault()
                            this.toggleTextResultModal(r.ansGroup, name, 'stress')
                          }}>{name}</a>
                        </>
                      ))
                    }</span></div></Col>
                  </Row>
                )) }
            </Col>
          </Row></>
          : <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>}
        </Folder>

        {/* {dimensoesMaisPontuadasRespondente[0] &&
        dimensoesMaisPontuadasRespondente[0].pontos > 0 ? (
          <h4>respondentes</h4>
        ) : null}
        <p>
          {dimensoesMaisPontuadasRespondente[0] &&
          dimensoesMaisPontuadasRespondente[0].pontos > 0 ? (
            textosRespondentes.map(texto => (
              <>
                <h5>{texto.tipo}</h5>
                <p>{texto.texto}</p>
              </>
            ))
          ) : (
            <p>Sem dados 2</p>
          )} */}
        {/* </p> */}
      </div>
    );
  }
}
